var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form',{attrs:{"onlyIcons":true}}),_c('section',[_c('article',{staticStyle:{"height":"173px","width":"411px"}},[_c('field',{staticStyle:{"top":"9px","left":"25px"},attrs:{"name":"codigo","widget":"input","searchable":"","label":"Código","placeholder":"Cód.","width":"67px","inputStyle":"font-weight:bold;text-align:center;"}}),_c('field',{staticStyle:{"top":"9px","left":"105px"},attrs:{"name":"nombre","widget":"input","searchable":"","label":"País","help":"Descripcion","placeholder":"Descripcion","width":"270px"}}),_c('field',{staticStyle:{"top":"49px","left":"25px"},attrs:{"name":"moneda_id","widget":"m2o","dbAdapter":"moneda","searchable":"","label":"Moneda","width":"120px"}}),_c('field',{staticStyle:{"top":"49px","left":"153px"},attrs:{"name":"abreviatura","widget":"input","searchable":"","label":"Abreviatura","placeholder":"","width":"67px"}}),_c('field',{staticStyle:{"top":"49px","left":"230px"},attrs:{"name":"impuesto_id","widget":"m2o","searchable":"","label":"Impuesto por defecto","width":"145px","fields":['nombre', 'valor'],"template-result":function (item) { return item.nombre
            ? item.nombre +
            ' (' +
            _vm.$utils.misc.formatNumber(item.valor) +
            '%)'
            : ''; },"template-selection":function (item) { return item.nombre
              ? item.nombre +
              ' (' +
              _vm.$utils.misc.formatNumber(item.valor) +
              '%)'
              : ''; }}}),_c('fieldset',{staticStyle:{"position":"relative","left":"30px","top":"89px","width":"230px","height":"59px"}},[_c('legend',[_vm._v("Nacionalidad:")]),_c('field',{staticStyle:{"top":"6px","left":"15px"},attrs:{"name":"nacionalidad","widget":"radio","default":"nacional","options":{
          Nacional: 'nacional',
          'Extracom.': 'extracomunitario',
          UE: 'union_europea',
        },"inline-label":""}})],1),_c('field',{staticStyle:{"top":"100px","right":"35px"},attrs:{"name":"activo_web","widget":"toggle","labels":{ checked: 'Activo', unchecked: 'Inactivo' },"width":75,"inline-label":"","searchable":""}})],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_c('div',{staticClass:"item-title"},[_vm._v("Listado")]),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }