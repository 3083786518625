<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 173px; width: 411px">
        <field name="codigo" widget="input" searchable label="Código" placeholder="Cód." width="67px"
          style="top: 9px; left: 25px" inputStyle="font-weight:bold;text-align:center;" />
        <field name="nombre" widget="input" searchable label="País" help="Descripcion" placeholder="Descripcion"
          width="270px" style="top: 9px; left: 105px" />
        <field name="moneda_id" widget="m2o" dbAdapter="moneda" searchable label="Moneda" width="120px"
          style="top: 49px; left: 25px" />
        <field name="abreviatura" widget="input" searchable label="Abreviatura" placeholder width="67px"
          style="top: 49px; left: 153px" />
        <field name="impuesto_id" widget="m2o" searchable label="Impuesto por defecto" width="145px"
          style="top: 49px; left: 230px" :fields="['nombre', 'valor']" :template-result="(item) =>
            item.nombre
              ? item.nombre +
              ' (' +
              $utils.misc.formatNumber(item.valor) +
              '%)'
              : ''
            " :template-selection="(item) =>
              item.nombre
                ? item.nombre +
                ' (' +
                $utils.misc.formatNumber(item.valor) +
                '%)'
                : ''
              " />
        <!--<field
          name="iva"
          widget="input"
          searchable
          label="IVA"
          placeholder="0.00"
          width="67px"
          style="top:49px; left:230px;"
        />
        <field
          name="re"
          widget="input"
          searchable
          label="RE"
          placeholder="0.00"
          width="67px"
          style="top:49px; left:307px;"
        />-->
        <fieldset style="
            position: relative;
            left: 30px;
            top: 89px;
            width: 230px;
            height: 59px;
          ">
          <legend>Nacionalidad:</legend>
          <field name="nacionalidad" widget="radio" default="nacional" :options="{
            Nacional: 'nacional',
            'Extracom.': 'extracomunitario',
            UE: 'union_europea',
          }" inline-label style="top: 6px; left: 15px" />
        </fieldset>
        <field name="activo_web" widget="toggle" :labels="{ checked: 'Activo', unchecked: 'Inactivo' }" :width="75"
          inline-label style="top: 100px; right: 35px" searchable />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    return {
      title: "Paises",
      dbAdapter: "pais",
      primary: "codigo",
      sequence: { name: "pais" },
    };
  },
};
</script>
